import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import MixpanelTracker from '../../utils/MixpanelTracker'
import QualificationHelper from '../../utils/QualificationHelper'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import { Form, Header, Button, Icon, Message, Modal } from 'semantic-ui-react'
import Knockout from './Knockout'
import ShortAnswer from './ShortAnswer'
import Choices from './Choices'
import AuthContext from '../../contexts/AuthContext'
import MessageContext from '../../contexts/MessageContext'
import DataContext from '../../contexts/DataContext'

const QualificationQuestions = ({
  updateQualificationResponses,
  onSubmit,
  project,
}) => {
  const { token } = useContext(AuthContext)
  const { setAlertMsg } = useContext(MessageContext)
  const dataContext = useContext(DataContext)
  const { qualificationQuestions, id } = project
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname

  const [rawResponses, setRawResponses] = useState([])

  const CONTENTS = {
    KNOCKOUT: 'knockout',
    SUPPLEMENTARY: 'supplementary',
    DECLINE: 'decline',
  }

  const knockoutQuestions = qualificationQuestions.filter(
    (q) => q.questionType === 'knockout'
  )
  const supplementaryQuestions = qualificationQuestions.filter(
    (q) => q.questionType !== 'knockout'
  )
  const [content, setContent] = useState(
    knockoutQuestions.length ? CONTENTS.KNOCKOUT : CONTENTS.SUPPLEMENTARY
  )
  const [errorMessage, setErrorMessage] = useState(null)

  const [declineProject, { loading }] = useMutation(UPDATE_JOB_MUTATION, {
    onCompleted: () => {
      setAlertMsg({
        title: 'Project Invitation Declined',
        content: 'You have successfully declined the project invitation.',
      })
      history.push('/projects/past')
    },
  })
  const onDecline = () => {
    MixpanelTracker.trackDeclinePublicProject()
    const sanitizedResponses =
      QualificationHelper.sanitizeResponses(rawResponses)
    declineProject({
      variables: {
        userId: dataContext?.advisor.id,
        projectId: id,
        job: {
          decline_feedback: 'This project is not a good fit for me',
          invite_status: 'Invited',
          response_status: 'Declined',
          overall_status: 'Declined',
          responseAt: new Date(),
          qualificationResponses: sanitizedResponses,
        },
      },
    })
  }

  const handleNext = () => {
    const sanitizedResponses =
      QualificationHelper.sanitizeResponses(rawResponses)
    if (content === CONTENTS.KNOCKOUT) {
      const yesKnockoutResponseCount = sanitizedResponses.reduce(
        (count, res) => count + (res.response[0] === 'Yes' ? 1 : 0),
        0
      )
      const validKnockouts =
        yesKnockoutResponseCount === knockoutQuestions.length

      if (validKnockouts) {
        if (supplementaryQuestions.length > 0) {
          setContent(CONTENTS.SUPPLEMENTARY)
          return
        }
        updateQualificationResponses(sanitizedResponses)
        return onSubmit()
      }
      if (token) setContent(CONTENTS.DECLINE)
      else history.push('/landing/invite-to-dexter')
      return
    }
    const errors = QualificationHelper.validateResponses({
      qualificationResponses: sanitizedResponses,
      qualificationQuestions,
    })
    if (errors.length > 0) {
      return setErrorMessage(errors)
    }
    updateQualificationResponses(sanitizedResponses)
    onSubmit()
  }
  const updateRawResponses = (response) => {
    setErrorMessage(null)
    setRawResponses(response)
  }
  const renderForm = () => {
    return (
      <div className="project-fit">
        <Form>
          {content !== CONTENTS.DECLINE && (
            <Header size="small">
              Please read the below question(s) carefully to determine if this
              project is a good match for you.
            </Header>
          )}
          <div id="qualifications-response">
            {content === CONTENTS.KNOCKOUT && (
              <>
                {knockoutQuestions.map((q, index) => (
                  <Knockout
                    key={`${q.question}+${index}`}
                    question={q}
                    rawResponses={rawResponses}
                    updateRawResponses={updateRawResponses}
                  />
                ))}
              </>
            )}

            {content === CONTENTS.SUPPLEMENTARY && (
              <>
                {supplementaryQuestions.map((q, index) => (
                  <div
                    className="qualification-question"
                    key={`${q.question}+${index}`}
                  >
                    <Form.Field>
                      <Header size="tiny">{q.question}</Header>

                      {q.questionType === 'shortAnswer' && (
                        <ShortAnswer
                          question={q}
                          rawResponses={rawResponses}
                          updateRawResponses={updateRawResponses}
                        />
                      )}

                      {q.questionType !== 'shortAnswer' && (
                        <Choices
                          question={q}
                          rawResponses={rawResponses}
                          updateRawResponses={updateRawResponses}
                        />
                      )}
                    </Form.Field>
                  </div>
                ))}
              </>
            )}

            {content === CONTENTS.DECLINE && (
              <div>
                <Header size="small">
                  We&apos;re sorry, but this project isn&apos;t the right fit
                  for you.
                </Header>

                <div>
                  Do you wish to decline this project? If you decline, you will
                  no longer receive notifications about it.
                </div>
              </div>
            )}
          </div>
        </Form>
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <>
        {content !== CONTENTS.KNOCKOUT && knockoutQuestions.length > 0 && (
          <Button
            icon
            onClick={() => {
              setErrorMessage(null)
              setContent(CONTENTS.KNOCKOUT)
            }}
            id="secondary-btn"
          >
            <Icon name="left chevron" />
          </Button>
        )}
        {content !== CONTENTS.DECLINE && (
          <Button
            className="primary-btn"
            primary
            onClick={handleNext}
            disabled={
              content === CONTENTS.KNOCKOUT &&
              rawResponses.length < knockoutQuestions.length
            }
          >
            Next
          </Button>
        )}
        {content === CONTENTS.DECLINE && (
          <Button
            id="decline"
            negative
            onClick={onDecline}
            loading={loading}
            icon
            labelPosition="left"
          >
            <Icon name="cancel" />
            Decline project
          </Button>
        )}
      </>
    )
  }

  if (path === '/projects') {
    return (
      <>
        <Modal.Content>
          {errorMessage && (
            <Message
              negative
              header="There were some errors with your submission"
              list={errorMessage}
            ></Message>
          )}
          {renderForm()}
        </Modal.Content>
        <Modal.Actions>{renderButtons()}</Modal.Actions>
      </>
    )
  } else {
    return (
      <>
        <div id="qualifications">
          {errorMessage && (
            <Message
              negative
              header="There were some errors with your submission"
              list={errorMessage}
            ></Message>
          )}
          <div id="qualifications-header">
            <Header sub>Project Fit</Header>
          </div>
          {renderForm()}
        </div>
        <div id="public-project-btn">{renderButtons()}</div>
      </>
    )
  }
}

export default QualificationQuestions
